@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600;700;900&display=swap");

body {
  margin: 0;
  transition: all 0.2s ease-in-out;
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  margin: auto;
  max-width: 1920px;
  /* background-color: #ccc; */
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* sweetalert2 ================================= */
.swal2-styled.swal2-confirm {
  background-color: #4d402d !important;
  font-family: "Kanit" !important;
}
.swal2-styled.swal2-deny {
  background-color: #dc3741 !important;
  font-family: "Kanit" !important;
}
.swal2-styled.swal2-cancel {
  font-family: "Kanit" !important;
}
.swal2-popup {
  font-family: "Kanit" !important;
}
.swal2-container {
  z-index: 10000 !important;
}
.swal2-actions {
  font-family: "Kanit" !important;
}
/* Mui Custom =================================*/
.MuiTableCell-root {
  padding: 8px !important;
}
.MuiTableSortLabel-icon {
  opacity: 1 !important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 10px !important;
}
.MuiStepLabel-label {
  font-size: 100%;
}
.Mui-disabled {
  color: #bfbfbf !important;
}
.MuiTableHead-root {
  background-color: #e1e1e1;
}
.MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: white !important;
}
/* .MuiTooltip-tooltip {
  background-color: transparent !important;
} */
.MuiTabs-indicator {
  background-color: #102c57;
}
.MuiTableRow-footer {
  background-color: #f0f0f0;
  font-weight: 700;
}

/* sideBar ================================= */
.smoothTransition {
  transition: all 0.2s ease-in-out;
}

.slideSideBar {
  animation: slideSideBarDown 0.5s ease-in-out forwards;
  opacity: 0;
  max-height: 0;
}
@keyframes slideSideBarDown {
  0% {
    /* visibility: hidden; */
    opacity: 0;
    max-height: 0;
  }
  100% {
    /* visibility: visible; */
    opacity: 1;
    max-height: 1000px;
  }
}
/* Truncate string */
.truncateText {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* blinking =================================*/
.blinking {
  animation: 1s blink ease infinite;
}
@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Loading =================================*/
.circleLoadingOne {
  transition: all 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  background-color: #102c57;
  border-radius: 50%;
  transform: scale(0);
  animation: circleExpandedOne 1s linear forwards;
  animation-iteration-count: infinite;
}
@keyframes circleExpandedOne {
  10% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.circleLoadingTwo {
  transition: all 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  transform: scale(0);
  animation: circleExpandedTwo 1s linear forwards;
  animation-iteration-count: infinite;
}
@keyframes circleExpandedTwo {
  20% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.circleLoadingThree {
  transition: all 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  background-color: #102c57;
  border-radius: 50%;
  transform: scale(0);
  animation: circleExpandedThree 1s linear forwards;
  animation-iteration-count: infinite;
}
@keyframes circleExpandedThree {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.circleLoadingFour {
  transition: all 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  transform: scale(0);
  animation: circleExpandedFour 1s linear forwards;
  animation-iteration-count: infinite;
}
@keyframes circleExpandedFour {
  70% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* Grid paper */
.grid-paper-white {
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-bottom: 16px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}
.boxCardCreate {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #f1f1f1;
  border-radius: 5px;
}
.itemPopUp {
  transition: all 0.2s ease-in-out;
  transform: scale(1);
  animation: imagePop 1s linear forwards;
}
@keyframes imagePop {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
}

.line-clamp-promotion {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  height: "50px";
}

/* ant design */

/* .ant-picker-input > input {
  color: white !important;
}
.ant-picker-separator {
  color: white !important;
}
.ant-picker-suffix {
  color: white !important;
}*/
.ant-space {
  width: 100% !important;
}
.ant-picker-focused {
  border-color: #1e2631 !important; /* Change border color when focused */
}

/* heatMapItem */
.heatMapItem {
  border-radius: "5px";
  /* scale: 1;
  z-index: 2;
  position: relative; */
}
@keyframes heatMap {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
}
.heatMapItem:hover {
  /* scale: 1.5; */
  opacity: 0.8;
  cursor: pointer;
  /* animation: heatMap 0.1s ease-in-out forwards; */
  /* animation: heatMap 1s linear forwards; */
}

/* grid */
.boxWrapper-container {
  display: grid;
  row-gap: 16px;
}
.dashed-line {
  border: none;
  border-top: 2px dashed #000; /* Adjust color as needed */
  height: 0; /* Set height to 0 to make it a horizontal line */
  margin: 16px 0; /* Adjust margin as needed */
}
.draggable-area {
  position: relative;
  width: 1300px;
  height: 700px;
  border: 1px solid #ccc;
}

.ball {
  width: 160px;
  /* position: absolute; */
  /* width: 100%;
  height: 100%; */
  /* border-radius: 25%; */
  cursor: pointer;
}
.imageGhost {
  pointer-events: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  animation: none;
  flex-wrap: nowrap;
  direction: ltr;
  @media (max-width: 768px) {
    display: inline-grid;
  }
}

.boxTable {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}
.top-left {
  left: -10px;
  top: -10px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
}

.top-right {
  right: -10px;
  top: -10px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
}

.bottom-left {
  left: -10px;
  bottom: -10px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
}

.bottom-right {
  right: -10px;
  bottom: -10px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
}
/* ================= resizable ================= */
.resizable {
  position: absolute;
}

.resizable img {
  width: 100%;
  height: 100%;
}

.resizable .resizers {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}

.resizable.selected .resizers {
  border: 3px solid #4286f4;
}

.resizable .resizers .resizer {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  border: 3px solid #4286f4;
  position: absolute;
}

.resizable .resizers .resizer.top-left {
  left: -5px;
  top: -5px;
  cursor: nwse-resize;
}

.resizable .resizers .resizer.top-right {
  right: -5px;
  top: -5px;
  cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-left {
  left: -5px;
  bottom: -5px;
  cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-right {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;
}

.resizable .resizers .rotator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 3px solid #4286f4;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  cursor: grab;
}
.resizable .resizers .isChecked {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  cursor: grab;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #514b82;
  mask: repeating-conic-gradient(#0000 0deg, #000 1deg 70deg, #0000 71deg 90deg),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 70deg,
      #0000 71deg 90deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l5 1s infinite;
}
@keyframes l5 {
  to {
    transform: rotate(0.5turn);
  }
}
